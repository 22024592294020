import DOMPurify from 'dompurify';

export class tinyMceHTMLCleaner {
  constructor(options = {}){
    Object.assign(this, {
      parser: {
        lowerCaseTags: true
      },
      transformTags: function(node) {
        if (!node.tagName) return node
        const alreadyTransformed = node.dataset.transformed === "true"
        let tag;
        switch(node.tagName) {
          case 'H1': tag = 'H2'; break;
          case 'H2': tag = 'H3'; break;
          case 'H3': tag = 'H4'; break;
          case 'H4': tag = 'H5'; break;
          case 'H5': tag = 'H6'; break;
          case 'P':
            if (Array.from(node.classList).some(c => c.startsWith('MsoListParagraph'))){
              tag = 'LI';
            }
            break;
        }
        if(tag && !alreadyTransformed) {
          const newElement = document.createElement(tag)
          newElement.innerHTML = node.innerHTML
          newElement.dataset.transformed = "true"
          node.outerHTML = newElement.outerHTML
        }
        return node
      },
      exclusiveFilter: function(node) {
        if(node.tagName != 'BR' && !node.textContent.trim()) {
          node.remove()
        }
        return node
      },
      removeHelperAttributes: function(node) {
        if (!node.tagName) return node
        delete node.dataset.transformed
      },
      textFilter: function(node) {
        if (node.nodeName && node.nodeName === '#text') {
          node.textContent = node.textContent.replace(/\u00a0|\u2022|\u00b7/g, " ")
        }
        return node
      },
      allowedTags : 'h1,h2,h3,h4,h5,h6,hr,br,p,b,i,em,strong,ul,ol,li,a'.split(','),
      allowedAttributes : ['href', 'name', 'target']
    }, options);
  }

  clean(html) {
    DOMPurify.addHook('beforeSanitizeElements', this.transformTags)
    DOMPurify.addHook('beforeSanitizeElements', this.textFilter)
    DOMPurify.addHook('beforeSanitizeElements', this.exclusiveFilter)
    DOMPurify.addHook('afterSanitizeElements', this.removeHelperAttributes)

    const result = DOMPurify.sanitize(html, {
      ALLOWED_TAGS: this.allowedTags,
      ALLOWED_ATTR: this.allowedAttributes
    })

    DOMPurify.removeAllHooks()

    return result
  }
}
